<template>
  <Dialog
    v-model:visible="activarModal"
    :style="{ width: '850px' }"
    header="Detalle Permisos"
    :modal="true"
    @hide="closeModal"
  >
    <h5>Asignar Permisos a Rol: <strong>{{ rol.name }}</strong></h5>

    <permissions-form
      :editarComponente="editarPermisos"
      :permisos-seleccionados="rolhijo.permissions"
      @enviarPermisos="permisosSeleccionados"
    ></permissions-form>

    <template #footer>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-secondary"
        @click="closeModal"
      />
      <Button
        label="Guardar"
        icon="pi pi-check"
        class="p-button-primary"
        @click="registrarPermisosRol"
      />
    </template>
  </Dialog>
</template>

<script>
import RolService from "@/service/RolService";
import PermissionsForm from "@/module/roles/PermissionsForm";

export default {
  components: {
    PermissionsForm,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    rol: {
      type: Object,
    },
  },
  data() {
    return {
      activarModal: this.show,
      rolhijo: this.rol,
      permisos_select: [],
      editarPermisos: true,
    };
  },
  rolService: null,
  created() {
    this.rolService = new RolService();
  },
  methods: {
    permisosSeleccionados(parms) {
      this.permisos_select = parms;
    },
    obtenernombrearray(elementos) {
      if (elementos == null) return [];

      let tmp = [];
      Object.keys(elementos).forEach((key) => {
        tmp.push(elementos[key].name);
      });

      return tmp;
    },
    registrarPermisosRol() {
      let datos = {
        rol_id: this.rolhijo.id,
        permisos: this.permisos_select,
      };

      this.rolService.asignarPermisosRol(datos).then(() => {
        this.permisos_select = [];
        this.$emit("actualizarRoles");
        this.$toast.add({
          severity: "success",
          summary: "Actualizado!",
          detail: "Actualizado Correctamente",
          life: 10000,
        });
        this.closeModal();
      });
    },
    closeModal() {
      this.activarModal = false;
      this.$emit("closeModal");
    },
  },
  watch: {
    show(value) {
      this.activarModal = value;
    },
    rol(value) {
      this.rolhijo = value;
      this.permisos_select = this.obtenernombrearray(this.rolhijo.permissions);
    },
  },
};
</script>
<style></style>
